






















































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const state = reactive({
      error: false,
      loading: false,
      alert: null as any,
    });

    const model = reactive({
      status: null,
    });

    const fetch = () => {
      state.loading = true;

      axiosInstance
        .get(`alert/${root.$route.params.alid}`)
        .then(({ data }) => {
          state.alert = data;
          model.status = data.isRead;
          state.error = false;
        })
        .catch(() => {
          state.error = true;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetch);

    watch(() => root.$route.params.alid, fetch);

    const updateStatus = () => {
      state.loading = true;

      axiosInstance
        .put(`alert/${root.$route.params.alid}`, { isRead: model.status })
        .then(fetch)
        .catch(() => {
          state.error = true;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    return {
      state,
      model,
      updateStatus,
    };
  },
});
